header#page-header {
  position: relative;

  div.fixed-header {
    position: fixed;
    width: 100vw;
    padding: 0;
    z-index: 1000;
    border-bottom: solid 1px $grey;
    background-color: rgba(255, 255, 255, .7);

    &>* {
      width: 100%;
    }

    >.container {
      .logo-wrapper {
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 15px;
        flex-grow: 1;

        @include breakpoint-md-min {
          padding-top: 10px;
          padding-left: 6px;
        }

        .homelink {
          display: block;
          height: 100%;

          &>svg {
            display: block;
            max-height: 40px;
            margin: unset;

            @include breakpoint-md-min {
              margin: unset;
              max-height: 94px;
            }
          }
        }
      }

      #mainMenu {
        margin: 0 -15px;
        padding: 7px 0;
        border-bottom: solid 1px $grey;
        position: absolute;
        top: 51px;
        left: 0;
        right: 0;
        padding-left: 15px;
        background-color: rgba(255, 255, 255, .7);

        @include breakpoint-md-min {
          margin: 0 25px 0 0;
          padding: 0;
          border-bottom: unset;
          background-color: unset;
          position: unset;
          left: unset;
          top: unset;
          right: unset;
        }

        &.collapse {
          @include breakpoint-md-min {
            display: block;
          }
        }

        nav.obis-bootstrap-menu {
          ul.nav {
            flex-direction: column;
            text-align: center;

            @include breakpoint-md-min {
              flex-direction: unset;
              text-align: unset;
              margin-top: 45px;
              border: none;
            }

            li.nav-item {
              padding: 2px 0;
              text-align: left;

              @include breakpoint-md-min {
                &:last-of-type {
                  &>.nav-link {
                    padding-right: 0;
                  }
                }
              }

              .nav-link {
                color: $grey;
                text-transform: uppercase;
                font-size: 14px;
                text-decoration: none;
                transition: color 0.2s ease-in-out;

                &:hover,
                &:focus,
                &:active {
                  color: #313131;
                }
              }

              &.active {
                .nav-link {
                  color: #313131;
                }
              }



              &.dropdown {
                .dropdown-toggle {
                  vertical-align: middle;
                  font-size: 110%;
                  max-height: 30px;
                  padding-bottom: 0;
                  padding-top: 0;

                  @include breakpoint-md-min {
                    font-size: unset;
                  }
                }

                ul.dropdown-menu {
                  border: 0;
                  font-size: 90%;
                  max-width: 80%;
                  margin: 0px auto;
                  background-color: rgba(255, 255, 255, 0.9);
                  transition: background-color 0.5s ease-in-out;

                  // Force-disable Bootstrap's auto-positioning
                  position: relative !important;
                  top: unset !important;
                  left: unset !important;
                  bottom: unset !important;
                  right: unset !important;
                  transform: unset !important;
                  float: none;
                  text-align: center;
                  margin-bottom: -25px;
                  border-bottom: 1px solid $black;
                  border-top: 1px solid $black;
                  border-radius: 0;

                  @include breakpoint-md-min {
                    position: absolute !important;
                    top: 97% !important;
                    left: 0 !important;
                    text-align: left;
                    float: unset;
                    margin-bottom: unset;
                    border-bottom: unset;
                    border-top: unset;
                    box-shadow: 1px 1px 4px -1px $black;
                  }

                  // END Force-disable Bootstrap's auto-positioning

                  @include breakpoint-sm-min {
                    max-width: 70%;
                  }

                  @include breakpoint-md-min {
                    max-width: unset;
                  }

                  li.dropdown-item {
                    padding: 0;
                    background: transparent;

                    .nav-link {
                      &>span {
                        border-bottom: 2px solid;
                        transition: border-bottom-color 0.3s ease-in-out;
                        white-space: normal;
                      }
                    }
                  }
                }

                @include breakpoint-md-min {
                  &:hover {
                    &>ul.dropdown-menu {
                      display: block;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .menu-button-mobile {
        position: fixed;
        right: 15px;
        top: 8px;
        transition: top 0.5s ease-in-out;

        @include breakpoint-md-min {
          display: none;
        }

        &>button {
          font-size: 21px;
          color: $grey;
          box-shadow: none;
          background-color: none !important;
          border: solid 1px;
          border-color: inherit;
          border-radius: 4px;
          padding: 0 12px;
        }
      }
    }
  }

  div.content-header {
    .obis-slider-slider {
      background-color: $blue;

      .carousel {
        margin-bottom: 0;

        .slick-slide {
          margin: unset;
        }

        .obis-slider-slide {
          &>.inner {
            justify-content: flex-start;

            picture, img {
              width: 100%;
            }

            &>.contents {
              background-color: transparent;
              color: $white;

              @include breakpoint-md-min {
                position: absolute;
                bottom: 30px;
                left: 0;
                right: 0;
                z-index: 2;
              }

              @include breakpoint-lg-min {
                bottom: 120px;
              }

              .neos-contentcollection {
                width: 100%;
                overflow: auto;
                padding: 0;

                @include breakpoint-md-min {
                  padding: 0 15px;
                  width: 75%;
                  background-color: rgba(11, 110, 165, 0.6);
                }

                @include breakpoint-lg-min {
                  width: 50%;
                }
              }

              h1,
              h2,
              h3 {
                margin-top: 20px;
                margin-bottom: 10px;
                line-height: 1.4;
                text-transform: uppercase;
                color: inherit;
              }

              h1 {
                font-size: 34px;
              }

              h2 {
                font-size: 26px;
              }

              h3 {
                font-size: 18px;
                font-weight: normal;
              }

              strong {
                color: inherit;
              }

              a {
                color: inherit;
                text-decoration: none;
              }
            }
            &:after {
              content: '';
              display: none;
              position: absolute;
              top: 0;
              width: 100%;
              height: 100%;
              background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(43,42,43,0) 50%, rgba(61,60,61,0.8) 100%);
              z-index: 1;

              @include breakpoint-lg-min {
                display: block;
              }
            }
          }
        }

        &>ul.pagination {
          top: 70px;
          bottom: unset;
          text-align: right;
          padding-right: 28px;
          left: 0;
          width: auto;

          @include breakpoint-md-min {
            width: 25%;
            left: 75%;
            top: unset;
            bottom: 25px;
          }

          @include breakpoint-lg-min {
            width: 50%;
            left: 50%;
            top: unset;
            bottom: 115px;
          }

          > li {
            button {
              &:before {
                color: $white;
                font-size: 8px;
                opacity: 1;
              }
            }
            &.slick-active {
              button {
                &:before {
                  color: $red;
                  font-size: 8px;
                }
              }
            }
          }
        }
      }
    }

    .static-header-image {
      width: 100%;
      height: 65vh;
      position: relative;

      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;

      &:after {
        content: '';
        display: none;
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(43,42,43,0) 50%, rgba(61,60,61,0.8) 100%);
        z-index: 1;

        @include breakpoint-lg-min {
          display: block;
        }
      }
    }
  }

  .quick-link-wrapper {
    border-top: 1px dashed $grey;
    border-bottom: 1px dashed $grey;
    text-align: center;
    padding: 20px 0 21px;
    background-color: $blue;

    @include breakpoint-lg-min {
      position: absolute;
      bottom: -1px;
      left: 0;
      right: 0;
      z-index: 2;
    }

    @include breakpoint-lg-min {
      background-color: rgba(255, 255, 255, 0.2);
    }

    .quick-link-item {
      a {
        color: $lightgrey;
        font-size: 28px;
        line-height: 40px;
        text-decoration: none;
        text-transform: uppercase;
      }

      &.active {
        a {
          color: $white;
        }
      }
    }
  }
}