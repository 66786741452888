.obis-form-form {
  font-size: 12pt;
  margin: 0 -15px;

  input,
  textarea,
  select {
    border-radius: 0.25rem;
    background: $white;
    color: $grey;
    border-color: inherit;

    &::placeholder {
      font-size: 11pt;
      color: $light;
    }

    &:focus {
      background: $light;
      color: $black;
    }
  }

  textarea {
    resize: vertical;
    min-height: 150px;
  }

  .gdpr-checkbox {
    font-size: 12pt;
  }

  .asterisk-notice {
    font-size: 10pt;
    font-style: italic;
    margin: 10px 0 15px;
    text-align: center;
  }

  .text-right {
    text-align: right;
  }

  label.control-label {
    font-weight: bold;
  }

  .form-navigation {

    li.prev,
    li.next,
    li.submit {
      flex-grow: 1;

      &>button {
        width: 100%;
        background-color: $blue;
        color: $white;
        text-transform: uppercase;
        border-radius: 0;
        transition: background-color 0.3s ease-in-out;
      }
    }
  }
}