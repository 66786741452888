@function fontStyleNameToWeight($style) {

  // if is already a proper number, return it directly with no conversion
  @if (type-of($style)=="number") {
    @return $style;
  }

  @if (str-index($style, "100")) {
    @return 100;
  }

  @if (str-index($style, "200")) {
    @return 200;
  }

  @if (str-index($style, "300")) {
    @return 300;
  }

  @if (str-index($style, "400")) {
    @return 400;
  }

  @if (str-index($style, "500")) {
    @return 500;
  }

  @if (str-index($style, "600")) {
    @return 600;
  }

  @if (str-index($style, "700")) {
    @return 700;
  }

  @if (str-index($style, "800")) {
    @return 800;
  }

  @if (str-index($style, "900")) {
    @return 900;
  }

  // see https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight#common_weight_name_mapping
  @if (str-index($style, "Thin")) {
    @return 100;
  }

  @if (str-index($style, "ExtraLight")) or (str-index($style, "UltraLight")) {
    @return 200;
  }

  @if (str-index($style, "Light")) {
    @return 300;
  }

  @if (str-index($style, "Normal")) or (str-index($style, "Regular")) {
    @return 400;
  }

  @if (str-index($style, "Medium")) {
    @return 500;
  }

  @if (str-index($style, "SemiBold")) or (str-index($style, "DemiBold")) {
    @return 600;
  }

  @if (str-index($style, "ExtraBold")) or (str-index($style, "UltraBold")) {
    @return 800;
  }

  // Check for "Bold" at the end to prevent false positives
  @if (str-index($style, "Bold")) {
    @return 700;
  }

  @if (str-index($style, "Black")) or (str-index($style, "Heavy")) {
    @return 900;
  }

  // Return "Regular" by default as these are mostly lacking their name in Italic variants ("Italic" instead of "RegularItalic")
  @return 400;
}

@function fontStyleNameToStyle($style) {
  @if (str-index($style, "Italic")) {
    @return italic;
  }

  @else {
    @return normal;
  }
}

@mixin font-face($name, $style: "Regular", $fontDisplay: fallback, $packageKey: "Obis.Site", $prefix: "/_Resources/Static/Packages/#{$packageKey}/Fonts") {
  @font-face {
    font-family: "#{$name}";
    src: url("#{$prefix}/#{$name}/#{$name}-#{$style}.woff2") format("woff2"),
         url("#{$prefix}/#{$name}/#{$name}-#{$style}.woff") format("woff"),
         url("#{$prefix}/#{$name}/#{$name}-#{$style}.ttf") format("truetype");

    font-weight: fontStyleNameToWeight($style);
    font-style: fontStyleNameToStyle($style);
    font-display: $fontDisplay;
  }
}