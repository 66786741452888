section.content-section {
  padding: 10px 0 0;

  @include breakpoint-md-min {
    padding: 10px 50px 0px;
  }

  &.full-width {
    padding: 10px 0 50px;

    &>.container-fluid {
      padding: 0;
    }
  }

  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  &.obis-site-section {

    &.has-background {

      &>.neos-contentcollection {
        background-color: rgba(255, 255, 255, 0.7);
        padding: 20px;
      }
    }
  }
}
