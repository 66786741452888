.neos-nodetypes-assetlist-assetlist {
  a {
    display: block;
    margin: 15px 0;
    text-decoration: none;
    text-align: center;
  }

  span {
    display: block;
    font-size: 15px;
    word-break: break-all;
  }

  i {
    display: block;
    margin: 10px 0;
    font-size: 70px;

    &.fa-download {
      font-size: 30px;
    }
  }
}