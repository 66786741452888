footer#page-footer .teaser-collection-wrapper > .neos-contentcollection {
  margin: 0 -15px;
  display: flex;
  flex-direction: column;
}
@media (min-width: 992px) {
  footer#page-footer .teaser-collection-wrapper > .neos-contentcollection {
    flex-direction: row;
    justify-content: space-between;
  }
}
footer#page-footer .contact-collection-wrapper > .row {
  display: flex;
  flex-direction: column;
  margin: 0 -15px;
  align-items: center;
  justify-content: center;
}
@media (min-width: 992px) {
  footer#page-footer .contact-collection-wrapper > .row {
    flex-direction: row;
    justify-content: space-between;
  }
}
footer#page-footer .contact-collection-wrapper > .row > a {
  background-color: #dddede;
  border-bottom: solid 2px #164395;
  display: block;
  flex: 1;
  position: relative;
  text-align: right;
  margin: 15px;
  padding: 8px 15px;
  min-height: 80px;
  max-height: 80px;
  overflow: hidden;
  text-decoration: none;
  width: calc(100% - 30px);
}
@media (min-width: 992px) {
  footer#page-footer .contact-collection-wrapper > .row > a {
    width: auto;
  }
}
footer#page-footer .contact-collection-wrapper > .row > a.infoTel {
  line-height: 62px;
  font-size: 22px;
}
footer#page-footer .contact-collection-wrapper > .row > a.infoMail {
  line-height: 62px;
}
footer#page-footer .contact-collection-wrapper > .row > a > i {
  position: absolute;
  left: 0;
  background-color: #164395;
  text-align: center;
  width: 75px;
  color: white;
  font-size: 20px;
  padding: 3px 0;
  height: 26px;
  top: 0;
  bottom: 0;
  margin: auto 0;
}
@media (min-width: 992px) {
  footer#page-footer .contact-collection-wrapper > .row > a > i {
    width: 75px;
  }
}
@media (min-width: 1200px) {
  footer#page-footer .contact-collection-wrapper > .row > a > i {
    width: 100px;
  }
}
footer#page-footer .footer-menu-wrapper {
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  footer#page-footer .footer-menu-wrapper {
    margin-bottom: 15px;
  }
}
footer#page-footer .footer-menu-wrapper .footer-menu {
  margin-top: 15px;
  text-align: center;
}
@media (min-width: 992px) {
  footer#page-footer .footer-menu-wrapper .footer-menu {
    text-align: left;
  }
}
footer#page-footer .footer-menu-wrapper .footer-menu .quick-link-item {
  margin-left: 0;
}
footer#page-footer .footer-menu-wrapper .footer-menu .quick-link-item a {
  text-decoration: none;
  text-transform: uppercase;
  color: #575656;
  font-size: 15px;
  padding-left: 0;
}
footer#page-footer .footer-menu-wrapper .footer-menu .quick-link-item a:after {
  content: "|";
  margin-left: 10px;
  color: #575656;
}
footer#page-footer .footer-menu-wrapper .footer-menu .quick-link-item:first-of-type {
  margin-left: 1px;
}
footer#page-footer .footer-menu-wrapper .footer-menu .quick-link-item:last-of-type a::after {
  display: none;
}
footer#page-footer .footer-menu-wrapper .logo-wrapper {
  display: none;
}
@media (min-width: 992px) {
  footer#page-footer .footer-menu-wrapper .logo-wrapper {
    display: block;
  }
}
footer#page-footer hr.color-grey {
  border-color: inherit;
}

header#page-header {
  position: relative;
}
header#page-header div.fixed-header {
  position: fixed;
  width: 100vw;
  padding: 0;
  z-index: 1000;
  border-bottom: solid 1px #575656;
  background-color: rgba(255, 255, 255, 0.7);
}
header#page-header div.fixed-header > * {
  width: 100%;
}
header#page-header div.fixed-header > .container .logo-wrapper {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
  flex-grow: 1;
}
@media (min-width: 768px) {
  header#page-header div.fixed-header > .container .logo-wrapper {
    padding-top: 10px;
    padding-left: 6px;
  }
}
header#page-header div.fixed-header > .container .logo-wrapper .homelink {
  display: block;
  height: 100%;
}
header#page-header div.fixed-header > .container .logo-wrapper .homelink > svg {
  display: block;
  max-height: 40px;
  margin: unset;
}
@media (min-width: 768px) {
  header#page-header div.fixed-header > .container .logo-wrapper .homelink > svg {
    margin: unset;
    max-height: 94px;
  }
}
header#page-header div.fixed-header > .container #mainMenu {
  margin: 0 -15px;
  padding: 7px 0;
  border-bottom: solid 1px #575656;
  position: absolute;
  top: 51px;
  left: 0;
  right: 0;
  padding-left: 15px;
  background-color: rgba(255, 255, 255, 0.7);
}
@media (min-width: 768px) {
  header#page-header div.fixed-header > .container #mainMenu {
    margin: 0 25px 0 0;
    padding: 0;
    border-bottom: unset;
    background-color: unset;
    position: unset;
    left: unset;
    top: unset;
    right: unset;
  }
}
@media (min-width: 768px) {
  header#page-header div.fixed-header > .container #mainMenu.collapse {
    display: block;
  }
}
header#page-header div.fixed-header > .container #mainMenu nav.obis-bootstrap-menu ul.nav {
  flex-direction: column;
  text-align: center;
}
@media (min-width: 768px) {
  header#page-header div.fixed-header > .container #mainMenu nav.obis-bootstrap-menu ul.nav {
    flex-direction: unset;
    text-align: unset;
    margin-top: 45px;
    border: none;
  }
}
header#page-header div.fixed-header > .container #mainMenu nav.obis-bootstrap-menu ul.nav li.nav-item {
  padding: 2px 0;
  text-align: left;
}
@media (min-width: 768px) {
  header#page-header div.fixed-header > .container #mainMenu nav.obis-bootstrap-menu ul.nav li.nav-item:last-of-type > .nav-link {
    padding-right: 0;
  }
}
header#page-header div.fixed-header > .container #mainMenu nav.obis-bootstrap-menu ul.nav li.nav-item .nav-link {
  color: #575656;
  text-transform: uppercase;
  font-size: 14px;
  text-decoration: none;
  transition: color 0.2s ease-in-out;
}
header#page-header div.fixed-header > .container #mainMenu nav.obis-bootstrap-menu ul.nav li.nav-item .nav-link:hover, header#page-header div.fixed-header > .container #mainMenu nav.obis-bootstrap-menu ul.nav li.nav-item .nav-link:focus, header#page-header div.fixed-header > .container #mainMenu nav.obis-bootstrap-menu ul.nav li.nav-item .nav-link:active {
  color: #313131;
}
header#page-header div.fixed-header > .container #mainMenu nav.obis-bootstrap-menu ul.nav li.nav-item.active .nav-link {
  color: #313131;
}
header#page-header div.fixed-header > .container #mainMenu nav.obis-bootstrap-menu ul.nav li.nav-item.dropdown .dropdown-toggle {
  vertical-align: middle;
  font-size: 110%;
  max-height: 30px;
  padding-bottom: 0;
  padding-top: 0;
}
@media (min-width: 768px) {
  header#page-header div.fixed-header > .container #mainMenu nav.obis-bootstrap-menu ul.nav li.nav-item.dropdown .dropdown-toggle {
    font-size: unset;
  }
}
header#page-header div.fixed-header > .container #mainMenu nav.obis-bootstrap-menu ul.nav li.nav-item.dropdown ul.dropdown-menu {
  border: 0;
  font-size: 90%;
  max-width: 80%;
  margin: 0px auto;
  background-color: rgba(255, 255, 255, 0.9);
  transition: background-color 0.5s ease-in-out;
  position: relative !important;
  top: unset !important;
  left: unset !important;
  bottom: unset !important;
  right: unset !important;
  transform: unset !important;
  float: none;
  text-align: center;
  margin-bottom: -25px;
  border-bottom: 1px solid #000;
  border-top: 1px solid #000;
  border-radius: 0;
}
@media (min-width: 768px) {
  header#page-header div.fixed-header > .container #mainMenu nav.obis-bootstrap-menu ul.nav li.nav-item.dropdown ul.dropdown-menu {
    position: absolute !important;
    top: 97% !important;
    left: 0 !important;
    text-align: left;
    float: unset;
    margin-bottom: unset;
    border-bottom: unset;
    border-top: unset;
    box-shadow: 1px 1px 4px -1px #000;
  }
}
@media (min-width: 576px) {
  header#page-header div.fixed-header > .container #mainMenu nav.obis-bootstrap-menu ul.nav li.nav-item.dropdown ul.dropdown-menu {
    max-width: 70%;
  }
}
@media (min-width: 768px) {
  header#page-header div.fixed-header > .container #mainMenu nav.obis-bootstrap-menu ul.nav li.nav-item.dropdown ul.dropdown-menu {
    max-width: unset;
  }
}
header#page-header div.fixed-header > .container #mainMenu nav.obis-bootstrap-menu ul.nav li.nav-item.dropdown ul.dropdown-menu li.dropdown-item {
  padding: 0;
  background: transparent;
}
header#page-header div.fixed-header > .container #mainMenu nav.obis-bootstrap-menu ul.nav li.nav-item.dropdown ul.dropdown-menu li.dropdown-item .nav-link > span {
  border-bottom: 2px solid;
  transition: border-bottom-color 0.3s ease-in-out;
  white-space: normal;
}
@media (min-width: 768px) {
  header#page-header div.fixed-header > .container #mainMenu nav.obis-bootstrap-menu ul.nav li.nav-item.dropdown:hover > ul.dropdown-menu {
    display: block;
  }
}
header#page-header div.fixed-header > .container .menu-button-mobile {
  position: fixed;
  right: 15px;
  top: 8px;
  transition: top 0.5s ease-in-out;
}
@media (min-width: 768px) {
  header#page-header div.fixed-header > .container .menu-button-mobile {
    display: none;
  }
}
header#page-header div.fixed-header > .container .menu-button-mobile > button {
  font-size: 21px;
  color: #575656;
  box-shadow: none;
  background-color: none !important;
  border: solid 1px;
  border-color: inherit;
  border-radius: 4px;
  padding: 0 12px;
}
header#page-header div.content-header .obis-slider-slider {
  background-color: #164395;
}
header#page-header div.content-header .obis-slider-slider .carousel {
  margin-bottom: 0;
}
header#page-header div.content-header .obis-slider-slider .carousel .slick-slide {
  margin: unset;
}
header#page-header div.content-header .obis-slider-slider .carousel .obis-slider-slide > .inner {
  justify-content: flex-start;
}
header#page-header div.content-header .obis-slider-slider .carousel .obis-slider-slide > .inner picture, header#page-header div.content-header .obis-slider-slider .carousel .obis-slider-slide > .inner img {
  width: 100%;
}
header#page-header div.content-header .obis-slider-slider .carousel .obis-slider-slide > .inner > .contents {
  background-color: transparent;
  color: #fff;
}
@media (min-width: 768px) {
  header#page-header div.content-header .obis-slider-slider .carousel .obis-slider-slide > .inner > .contents {
    position: absolute;
    bottom: 30px;
    left: 0;
    right: 0;
    z-index: 2;
  }
}
@media (min-width: 992px) {
  header#page-header div.content-header .obis-slider-slider .carousel .obis-slider-slide > .inner > .contents {
    bottom: 120px;
  }
}
header#page-header div.content-header .obis-slider-slider .carousel .obis-slider-slide > .inner > .contents .neos-contentcollection {
  width: 100%;
  overflow: auto;
  padding: 0;
}
@media (min-width: 768px) {
  header#page-header div.content-header .obis-slider-slider .carousel .obis-slider-slide > .inner > .contents .neos-contentcollection {
    padding: 0 15px;
    width: 75%;
    background-color: rgba(11, 110, 165, 0.6);
  }
}
@media (min-width: 992px) {
  header#page-header div.content-header .obis-slider-slider .carousel .obis-slider-slide > .inner > .contents .neos-contentcollection {
    width: 50%;
  }
}
header#page-header div.content-header .obis-slider-slider .carousel .obis-slider-slide > .inner > .contents h1,
header#page-header div.content-header .obis-slider-slider .carousel .obis-slider-slide > .inner > .contents h2,
header#page-header div.content-header .obis-slider-slider .carousel .obis-slider-slide > .inner > .contents h3 {
  margin-top: 20px;
  margin-bottom: 10px;
  line-height: 1.4;
  text-transform: uppercase;
  color: inherit;
}
header#page-header div.content-header .obis-slider-slider .carousel .obis-slider-slide > .inner > .contents h1 {
  font-size: 34px;
}
header#page-header div.content-header .obis-slider-slider .carousel .obis-slider-slide > .inner > .contents h2 {
  font-size: 26px;
}
header#page-header div.content-header .obis-slider-slider .carousel .obis-slider-slide > .inner > .contents h3 {
  font-size: 18px;
  font-weight: normal;
}
header#page-header div.content-header .obis-slider-slider .carousel .obis-slider-slide > .inner > .contents strong {
  color: inherit;
}
header#page-header div.content-header .obis-slider-slider .carousel .obis-slider-slide > .inner > .contents a {
  color: inherit;
  text-decoration: none;
}
header#page-header div.content-header .obis-slider-slider .carousel .obis-slider-slide > .inner:after {
  content: "";
  display: none;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(43, 42, 43, 0) 50%, rgba(61, 60, 61, 0.8) 100%);
  z-index: 1;
}
@media (min-width: 992px) {
  header#page-header div.content-header .obis-slider-slider .carousel .obis-slider-slide > .inner:after {
    display: block;
  }
}
header#page-header div.content-header .obis-slider-slider .carousel > ul.pagination {
  top: 70px;
  bottom: unset;
  text-align: right;
  padding-right: 28px;
  left: 0;
  width: auto;
}
@media (min-width: 768px) {
  header#page-header div.content-header .obis-slider-slider .carousel > ul.pagination {
    width: 25%;
    left: 75%;
    top: unset;
    bottom: 25px;
  }
}
@media (min-width: 992px) {
  header#page-header div.content-header .obis-slider-slider .carousel > ul.pagination {
    width: 50%;
    left: 50%;
    top: unset;
    bottom: 115px;
  }
}
header#page-header div.content-header .obis-slider-slider .carousel > ul.pagination > li button:before {
  color: #fff;
  font-size: 8px;
  opacity: 1;
}
header#page-header div.content-header .obis-slider-slider .carousel > ul.pagination > li.slick-active button:before {
  color: #e20000;
  font-size: 8px;
}
header#page-header div.content-header .static-header-image {
  width: 100%;
  height: 65vh;
  position: relative;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
header#page-header div.content-header .static-header-image:after {
  content: "";
  display: none;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(43, 42, 43, 0) 50%, rgba(61, 60, 61, 0.8) 100%);
  z-index: 1;
}
@media (min-width: 992px) {
  header#page-header div.content-header .static-header-image:after {
    display: block;
  }
}
header#page-header .quick-link-wrapper {
  border-top: 1px dashed #575656;
  border-bottom: 1px dashed #575656;
  text-align: center;
  padding: 20px 0 21px;
  background-color: #164395;
}
@media (min-width: 992px) {
  header#page-header .quick-link-wrapper {
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    z-index: 2;
  }
}
@media (min-width: 992px) {
  header#page-header .quick-link-wrapper {
    background-color: rgba(255, 255, 255, 0.2);
  }
}
header#page-header .quick-link-wrapper .quick-link-item a {
  color: #cfd7dc;
  font-size: 28px;
  line-height: 40px;
  text-decoration: none;
  text-transform: uppercase;
}
header#page-header .quick-link-wrapper .quick-link-item.active a {
  color: #fff;
}

.neos-nodetypes-assetlist-assetlist a {
  display: block;
  margin: 15px 0;
  text-decoration: none;
  text-align: center;
}
.neos-nodetypes-assetlist-assetlist span {
  display: block;
  font-size: 15px;
  word-break: break-all;
}
.neos-nodetypes-assetlist-assetlist i {
  display: block;
  margin: 10px 0;
  font-size: 70px;
}
.neos-nodetypes-assetlist-assetlist i.fa-download {
  font-size: 30px;
}

hr.obis-nodetypes-divider {
  border-width: 2px;
  margin: 15px 0;
}

.obis-form-form {
  font-size: 12pt;
  margin: 0 -15px;
}
.obis-form-form input,
.obis-form-form textarea,
.obis-form-form select {
  border-radius: 0.25rem;
  background: #fff;
  color: #575656;
  border-color: inherit;
}
.obis-form-form input::placeholder,
.obis-form-form textarea::placeholder,
.obis-form-form select::placeholder {
  font-size: 11pt;
  color: #dddede;
}
.obis-form-form input:focus,
.obis-form-form textarea:focus,
.obis-form-form select:focus {
  background: #dddede;
  color: #000;
}
.obis-form-form textarea {
  resize: vertical;
  min-height: 150px;
}
.obis-form-form .gdpr-checkbox {
  font-size: 12pt;
}
.obis-form-form .asterisk-notice {
  font-size: 10pt;
  font-style: italic;
  margin: 10px 0 15px;
  text-align: center;
}
.obis-form-form .text-right {
  text-align: right;
}
.obis-form-form label.control-label {
  font-weight: bold;
}
.obis-form-form .form-navigation li.prev,
.obis-form-form .form-navigation li.next,
.obis-form-form .form-navigation li.submit {
  flex-grow: 1;
}
.obis-form-form .form-navigation li.prev > button,
.obis-form-form .form-navigation li.next > button,
.obis-form-form .form-navigation li.submit > button {
  width: 100%;
  background-color: #164395;
  color: #fff;
  text-transform: uppercase;
  border-radius: 0;
  transition: background-color 0.3s ease-in-out;
}

.google-map .canvas {
  height: 350px;
}

section.content-section {
  padding: 10px 0 0;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
@media (min-width: 768px) {
  section.content-section {
    padding: 10px 50px 0px;
  }
}
section.content-section.full-width {
  padding: 10px 0 50px;
}
section.content-section.full-width > .container-fluid {
  padding: 0;
}
section.content-section.obis-site-section.has-background > .neos-contentcollection {
  background-color: rgba(255, 255, 255, 0.7);
  padding: 20px;
}

.obis-slider-slider .slick-slide {
  height: unset;
}

.obis-nodetypes-svgicon {
  padding: 0;
  padding-bottom: 10px;
}
.obis-nodetypes-svgicon.element-position-left svg {
  display: block;
  margin-left: 0;
  margin-right: auto;
}
.obis-nodetypes-svgicon.element-position-center svg {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.obis-nodetypes-svgicon.element-position-left svg {
  display: block;
  margin-left: auto;
  margin-right: 0;
}
.obis-nodetypes-svgicon.size-tiny svg {
  width: 25px;
}
.obis-nodetypes-svgicon.size-small svg {
  width: 50px;
}
.obis-nodetypes-svgicon.size-mediumsmall svg {
  width: 100px;
}
.obis-nodetypes-svgicon.size-medium svg {
  width: 160px;
}
.obis-nodetypes-svgicon.size-large svg {
  width: 200px;
}
.obis-nodetypes-svgicon.size-quarter svg {
  width: 25%;
}
.obis-nodetypes-svgicon.size-half svg {
  width: 50%;
}
.obis-nodetypes-svgicon.size-full svg {
  width: 100%;
}

.obis-site-teaser {
  flex: 1;
  background-color: #dddede;
  margin: 15px;
  border-bottom: solid 2px #164395;
}
.obis-site-teaser .teaser-header {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  height: 120px;
}
.obis-site-teaser .teaser-header:before {
  content: " ";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(11, 110, 165, 0.6);
}
.obis-site-teaser .teaser-header .obis-nodetypes-svgicon {
  position: relative;
  z-index: 2;
}
.obis-site-teaser .teaser-header .obis-nodetypes-svgicon svg {
  width: auto;
  margin: 0 auto;
  padding-top: 20px;
  height: 100px;
  display: block;
  color: #fff;
}
.obis-site-teaser h4 {
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  background-color: #164395;
  padding: 10px 10px 5px;
  margin: 0;
  font-size: 18px;
}
@media (min-width: 768px) {
  .obis-site-teaser h4 {
    padding-bottom: 7px;
  }
}
.obis-site-teaser p {
  padding: 20px 10px;
  text-align: center;
  display: none;
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .obis-site-teaser p {
    display: block;
  }
}
.obis-site-teaser a {
  text-decoration: none;
}

.neos-nodetypes-text .table {
  overflow-x: auto;
}
.neos-nodetypes-text table thead {
  background-color: #dddede;
}
.neos-nodetypes-text table thead th {
  border-color: #575656;
}
.neos-nodetypes-text table td,
.neos-nodetypes-text table th {
  font-weight: normal;
  border: solid 1px #575656;
  padding: 0.35rem;
}
@media (min-width: 768px) {
  .neos-nodetypes-text table td,
.neos-nodetypes-text table th {
    padding: 0.5rem;
  }
}
.neos-nodetypes-text table td p,
.neos-nodetypes-text table th p {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: "Roboto";
  src: url("/_Resources/Static/Packages/Obis.Site/Fonts/Roboto/Roboto-Regular.woff2") format("woff2"), url("/_Resources/Static/Packages/Obis.Site/Fonts/Roboto/Roboto-Regular.woff") format("woff"), url("/_Resources/Static/Packages/Obis.Site/Fonts/Roboto/Roboto-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: "Roboto";
  src: url("/_Resources/Static/Packages/Obis.Site/Fonts/Roboto/Roboto-Bold.woff2") format("woff2"), url("/_Resources/Static/Packages/Obis.Site/Fonts/Roboto/Roboto-Bold.woff") format("woff"), url("/_Resources/Static/Packages/Obis.Site/Fonts/Roboto/Roboto-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
}
body {
  overflow-x: hidden;
  color: #575656;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}
body a {
  color: #575656;
  text-decoration: underline;
}
body a:hover,
body a:focus,
body a:active {
  color: #575656;
  text-decoration: none;
}
body h1,
body h2,
body h3 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.4;
}
body h1 a,
body h2 a,
body h3 a {
  text-decoration: none;
}
body h1 {
  font-size: 23px;
}
body h2 {
  font-size: 20px;
}
body h3 {
  font-size: 17px;
}
body p {
  margin: 0 0 15px;
}