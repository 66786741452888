footer#page-footer {
  .teaser-collection-wrapper {
    >.neos-contentcollection {
      margin: 0 -15px;
      display: flex;
      flex-direction: column;

      @include breakpoint-lg-min {
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }

  .contact-collection-wrapper {
    >.row {
      display: flex;
      flex-direction: column;
      margin: 0 -15px;
      align-items: center;
      justify-content: center;

      @include breakpoint-lg-min {
        flex-direction: row;
        justify-content: space-between;
      }

      >a {
        background-color: $light;
        border-bottom: solid 2px $blue;
        display: block;
        flex: 1;
        position: relative;
        text-align: right;
        margin: 15px;
        padding: 8px 15px;
        min-height: 80px;
        max-height: 80px;
        overflow: hidden;
        text-decoration: none;
        width: calc(100% - 30px);

        @include breakpoint-lg-min {
          width: auto;
        }

        &.infoTel {
          line-height: 62px;
          font-size: 22px;
        }

        &.infoMail {
          line-height: 62px;
        }

        >i {
          position: absolute;
          left: 0;
          background-color: $blue;
          text-align: center;
          width: 75px;
          color: white;
          font-size: 20px;
          padding: 3px 0;
          height: 26px;
          top: 0;
          bottom: 0;
          margin: auto 0;

          @include breakpoint-lg-min {
            width: 75px
          }

          @include breakpoint-xl-min {
            width: 100px
          }
        }
      }
    }
  }

  .footer-menu-wrapper {
    margin-bottom: 30px;

    @include breakpoint-md-min {
      margin-bottom: 15px;
    }

    .footer-menu {
      margin-top: 15px;
      text-align: center;

      @include breakpoint-lg-min {
        text-align: left;
      }

      .quick-link-item {
        margin-left: 0;

        a {
          text-decoration: none;
          text-transform: uppercase;
          color: $grey;
          font-size: 15px;
          padding-left: 0;

          &:after {
            content: "|";
            margin-left: 10px;
            color: $grey;
          }
        }

        &:first-of-type {
          margin-left: 1px;
        }

        &:last-of-type {
          a {
            &::after {
              display: none;
            }
          }
        }
      }
    }

    .logo-wrapper {
      display: none;

      @include breakpoint-lg-min {
        display: block;
      }
    }
  }

  hr.color-grey {
    border-color: inherit;
  }
}