.obis-site-teaser {
  flex: 1;
  background-color: $light;
  margin: 15px;
  border-bottom: solid 2px $blue;

  .teaser-header {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    position: relative;
    height: 120px;

    &:before {
      content: ' ';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: rgba(11, 110, 165, 0.6);
    }

    .obis-nodetypes-svgicon {
      position: relative;
      z-index: 2;

      svg {
        width: auto;
        margin: 0 auto;
        padding-top: 20px;
        height: 100px;
        display: block;
        color: $white;
      }
    }
  }

  h4 {
    color: $white;
    text-align: center;
    text-transform: uppercase;
    background-color: $blue;
    padding: 10px 10px 5px;
    margin: 0;
    font-size: 18px;

    @include breakpoint-md-min {
      padding-bottom: 7px;
    }
  }

  p {
    padding: 20px 10px;
    text-align: center;
    display: none;
    margin-bottom: 0;

    @include breakpoint-md-min {
      display: block;
    }
  }

  a {
    text-decoration: none;
  }
}