// General basic styling
body {
  overflow-x: hidden;
  color: $grey;
  font-family: "Roboto", sans-serif;
  font-size: 14px;

  a {
    color: $grey;
    text-decoration: underline;
  }

  a:hover,
  a:focus,
  a:active {
    color: $grey;
    text-decoration: none;
  }

  h1,
  h2,
  h3 {
    margin-top: 20px;
    margin-bottom: 10px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1.4;

    a {
      text-decoration: none;
    }
  }

  h1{
    font-size: 23px;
  }

  h2 {
    font-size: 20px;
  }

  h3 {
    font-size: 17px;
  }

  p {
    margin: 0 0 15px;
  }
}