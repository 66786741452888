.neos-nodetypes-text {
  .table {
    overflow-x: auto;
  }
  table {

    thead {
      background-color: $light;

      th {
        border-color: $grey;
      }
    }

    td,
    th {
      font-weight: normal;
      border: solid 1px $grey;
      padding: 0.35rem;

      @include breakpoint-md-min {
        padding: 0.5rem;
      }

      p {
        margin: 0; 
        padding: 0;
      }
    }
  }
}