.obis-nodetypes-svgicon {
  padding: 0;
  padding-bottom: 10px;

  &.element-position-left svg {
    display: block;
    margin-left: 0;
    margin-right: auto;
  }

  &.element-position-center svg {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  &.element-position-left svg {
    display: block;
    margin-left: auto;
    margin-right: 0;
  }

  &.size-tiny svg {
    width: 25px;
  }

  &.size-small svg {
    width: 50px;
  }

  &.size-mediumsmall svg {
    width: 100px;
  }

  &.size-medium svg {
    width: 160px;
  }

  &.size-large svg {
    width: 200px;
  }

  &.size-quarter svg {
    width: 25%;
  }

  &.size-half svg {
    width: 50%;
  }

  &.size-full svg {
    width: 100%;
  }
}